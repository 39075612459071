/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it

//self-host Google Fonts with Fontsource
import '@fontsource/manrope';
import '@fontsource/manrope/500.css';
import '@fontsource/manrope/700.css';

export { wrapRootElement } from './src/apollo/wrap-root-element';

// fix browser cache issues after site updates
// https://github.com/gatsbyjs/gatsby/issues/9087
export const onServiceWorkerUpdateReady = () => window.location.reload();

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const currentPosition = getSavedScrollPosition(location);
  if (location.pathname.startsWith('/careers/Position')) {
    return false;
  }
  window.scrollTo(...(currentPosition || [0, 0]));
};

// const appendCookieconsent = (scriptInnerHTML) => {
//   const scriptCookieconsent = document.createElement('script');
//   scriptCookieconsent.dataset.cookieconsent = 'ignore';
//   scriptCookieconsent.type = 'text/javascript';
//   scriptCookieconsent.innerHTML = scriptInnerHTML;

//   document.head.appendChild(scriptCookieconsent);
// };
// const appendCookiebot = () => {
//   const scriptCookiebot = document.createElement('script');
//   scriptCookiebot.id = 'Cookiebot';
//   scriptCookiebot.type = true;
//   scriptCookiebot.async = 'text/javascript';
//   scriptCookiebot.id = 'https://consent.cookiebot.com/uc.js';
//   scriptCookiebot.dataset.cbid = '2e4b2aa6-8f31-4c6c-b8f8-e1aaadcac602';

//   document.head.appendChild(scriptCookiebot);
// };

// Callback to append scripts
// const appendThirdPartyScripts = () => {
//   appendCookieconsent(`window.dataLayer = window.dataLayer || [];
//                   function gtag() {
//                   dataLayer.push(arguments);
//                   }
//                   gtag("consent", "default", {
//                   analytics_storage: "granted",
//                   });
//                   gtag("consent", "default", {
//                   analytics_storage: "denied",
//                   region: ['ES', 'US-AK'],
//                   });
//                   gtag("consent", "default", {
//                   ad_storage: "denied",
//                   functionality_storage: "denied",
//                   personalization_storage: "denied",
//                   security_storage: "granted",
//                   wait_for_update: 500,
//                   });
//                   gtag("set", "ads_data_redaction", true);
//                   gtag("set", "url_passthrough", true);`);

//   appendCookieconsent(`(function(w, d, s, l, i) {
//             w[l] = w[l] || [];
//             w[l].push({
//                 'gtm.start': new Date().getTime(),
//                 event: 'gtm.js'
//             });
//             var f = d.getElementsByTagName(s)[0],
//                 j = d.createElement(s),
//                 dl = l != 'dataLayer' ? '&l=' + l : '';
//             j.async = true;
//             j.src =
//                 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
//             f.parentNode.insertBefore(j, f);
//         })(window, document, 'script', 'dataLayer', 'GTM-MVDM8Q7');`);

//   appendCookiebot();
// };

// export const onInitialClientRender = () => {
//   window.addEventListener('load', appendThirdPartyScripts, { once: true });
// };
