module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Brocoders","short_name":"Brocoders","start_url":"/","background_color":"#d91965","theme_color":"#d91965","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d4aaf881da05d59992638eee0b436e53"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 460px)","s":"(max-width: 768px)","m":"(max-width: 1024px)","l":"(max-width: 1440px)","xl":"(max-width: 1980px)"}},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://715a539043f14e559fd9a6615ed950a8@o435702.ingest.sentry.io/5395466","sampleRate":1,"environment":"release","maxValueLength":1000,"ignoreErrors":["ResizeObserver loop limit exceeded"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
